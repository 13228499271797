<template>
    <div class="page-container">
      <div class="sidebar-menu toggle-others fixed">
        <div class="sidebar-menu-inner">
          <header class="logo-env">
            <!-- logo -->
            <div class="logo">
              <a href="javascript:void(0)" class="logo-expanded">
                <img src="../assets/images/logo@2x.svg" width="100%" alt="" />
              </a>
              <a href="javascript:void(0)" class="logo-collapsed">
                <img
                  src="../assets/images/logo-collapsed@2x.png"
                  width="40"
                  alt=""
                />
              </a>
            </div>
            <div class="mobile-menu-toggle visible-xs">
              <a href="javascript:void(0)" data-toggle="user-info-menu">
                <i class="linecons-cog"></i>
              </a>
              <a href="javascript:void(0)" data-toggle="mobile-menu">
                <i class="fa-bars"></i>
              </a>
            </div>
          </header>
          <!-- 侧边栏 -->
          <ul id="main-menu" class="main-menu">
            <li v-for="(menu, idx) in items" :key="idx">
              <a :href="'#' + transName(menu)" class="smooth">
                <i :class="menu.icon"></i>
                <span class="title">{{ transName(menu) }}</span>
              </a>
              <ul v-if="menu.children">
                <li v-for="(submenu, idx) in menu.children" :key="idx">
                  <a :href="'#' + transName(submenu)" class="smooth">
                    <span class="title">{{ transName(submenu) }}</span>
                    <span
                      v-show="submenu.is_hot"
                      class="label label-pink pull-right hidden-collapsed"
                      >Hot</span
                    >
                  </a>
                </li>
              </ul>
            </li>
            <!-- 关于本站 -->
            <!-- <li class="submit-tag">
              <router-link to="/about">
                <i class="linecons-heart"></i>
                <span class="tooltip-blue">关于本站</span>
                <span class="label label-Primary pull-right hidden-collapsed"
                  >♥︎</span
                >
              </router-link>
            </li> -->
            <!-- 广告 -->
            
  
          </ul>
        <div class="_zzhw6zrpvle"></div>
          
        </div>
      </div>
  
      <div class="main-content">

        <!-- 顶部的 多语言 关闭展开按钮 -->
        <nav class="navbar user-info-navbar" role="navigation">
          <ul class="user-info-menu left-links list-inline list-unstyled">
            <li class="hidden-sm hidden-xs">
              <a href="javascript:void(0)" data-toggle="sidebar"><i class="fa-bars"></i></a>
            </li>
            <li class="dropdown hover-line language-switcher">
              <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                <img :src="lang.flag" /> {{ lang.name }}
              </a>
              <ul class="dropdown-menu languages">
                <li
                  :class="{ active: langItem.key === lang.key }"
                  v-for="langItem in langList"
                  :key="langItem.key"
                >
                  <a href="javascript:void(0)" @click="lang = langItem">
                    <img :src="langItem.flag" /> {{ langItem.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="user-info-menu right-links list-inline list-unstyled">
            <li class="hidden-sm hidden-xs">
              <!-- <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
                <i class="fa-github"></i> GitHub
              </a> -->
            </li>
          </ul>
        </nav>
  
        <div v-for="(item, idx) in itemsSites" :key="idx">
          <div>
            <!-- <div class="_3uizw4l0ny5"></div> -->
            <UnionAd id="u6919892"></UnionAd>
          </div>
          <div v-if="item.web">
            <WebItem :item="item" :transName="transName" />
          </div>
          <div v-else v-for="(subItem, idx) in item.children" :key="idx">
            <WebItem :item="subItem" :transName="transName" />
          </div>
        </div>
  
        <Footer />
      </div>
    </div>
  </template>
  
  <script>
  import WebItem from "../components/WebItem.vue";
  import Footer from "../components/Footer.vue";
  import itemsData from "../assets/data.json";
  import itemsSites from "../assets/data_develop.json";
  import { loadJs } from '../assets/js/app.js'
  
  export default {
    name: "Index",
    components: {
      WebItem,
      Footer,
    },
    data() {
      return {
        items: itemsData,
        itemsSites:itemsSites,
        lang: {},
        langList: [
          {
            key: "zh",
            name: "简体中文",
            flag: "./assets/images/flags/flag-cn.png",
          },
          {
            key: "en",
            name: "English",
            flag: "./assets/images/flags/flag-us.png",
          },
        ],
      };
    },
    created() {
      this.lang = this.langList[0];
      loadJs();
    },
    methods: {
      transName(webItem) {
        return this.lang.key === "en" ? webItem.en_name : webItem.name;
      },
    },
  };
  </script>
  
  <style>
  </style>
  